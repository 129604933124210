/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

const { default: axios } = require("axios");

require("./bootstrap");

// require("datatables.net");
require("datatables.net-bs5");
require("datatables.net-fixedheader-bs5");
var querystring = require('querystring');
// require("datatables.net-responsive-bs5/js/responsive.bootstrap5.min.js");
$.extend(true, $.fn.dataTable.defaults, {
    language: {
        sProcessing: "กำลังดำเนินการ...",
        sLengthMenu: "แสดง_MENU_ แถว",
        sZeroRecords: "ไม่พบข้อมูล",
        sInfo: "แสดง _START_ ถึง _END_ จาก _TOTAL_ แถว",
        sInfoEmpty: "แสดง 0 ถึง 0 จาก 0 แถว",
        sInfoFiltered: "(กรองข้อมูล _MAX_ ทุกแถว)",
        sInfoPostFix: "",
        sSearch: "ค้นหา:",
        sUrl: "",
        oPaginate: {
            sFirst: "เิริ่มต้น",
            sPrevious: "ก่อนหน้า",
            sNext: "ถัดไป",
            sLast: "สุดท้าย",
        },
    },
});

$(function () {
    $(".getedit").on("click", function () {
        $("#name").val($(this).data("name"));
        $("#email").val($(this).data("email"));
        $("#role").val($(this).data("role"));
        $("#formId").attr("action", "./users/" + $(this).data("id"));
        $("#uid").val($(this).data("id"));
    });

    var table = $(".yajra-datatable").DataTable({
        responsive: true,
        fixedHeader: true,
        bStateSave: true,
        fnStateSave: function (oSettings, oData) {
            localStorage.setItem("offersDataTables", JSON.stringify(oData));
        },
        fnStateLoad: function (oSettings) {
            return JSON.parse(localStorage.getItem("offersDataTables"));
        },
        "lengthMenu": [[50, 200, 500, 1000, -1], [50, 200, 500, 1000, "All"]],
        processing: true,
        serverSide: true,
        ajax: "/rider/list",
        columns: [
            // {data: 'id', name: 'id'},

            { data: "region", name: "region" },
            { data: "province", name: "province" },
            { data: "district", name: "district" },
            // { data: "details", name: "details" },
            {

                "render": function ( data, type, row ) {
                    return row["details"];
                },
                "targets": 0
            },
            { data: "price_chicken", name: "price_chicken" },
            // { data: "price_bird", name: "price_bird" },
            // { data: "price_cat", name: "price_cat" },
            { data: "price_dog", name: "price_dog" },
            // { data: "price_other", name: "price_other" },
            {
                data: "action",
                name: "action",
                orderable: true,
                searchable: true,
            },
        ],
    });

    // new $.fn.dataTable.FixedHeader( table );
});

$(document).on("click", ".edit", function () {
    $("#id").val($(this).data("id"));
    $("#province").val($(this).data("province"));

    $("#region").val($(this).data("region")).change();
    $("#district").val($(this).data("district"));
    $("#details").text(decodeBase64($(this).data("details")));

    $("#price_chicken").val($(this).data("price_chicken"));
    $("#price_cat").val($(this).data("price_cat"));
    $("#price_dog").val($(this).data("price_dog"));
    $("#price_bird").val($(this).data("price_bird"));
    $("#price_other").val($(this).data("price_other"));
    $("#formedit").attr("action", "/manage_rider/" + $(this).data("id"));
});
$(document).on("click", ".delete", function () {
    // alert($(this).data('id'));
    Swal.fire({
        title: "ยืนยัน?",
        text: "คุณต้องการลบออกหรือไม่!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ใช่ ต้องการลบ!",
    }).then((result) => {
        if (result.isConfirmed) {
            axios.delete("/manage_rider/" + $(this).data("id")).then(
                (response) => {
                    Swal.fire("Deleted!", "ลบสำเร็จ.", "success");
                },
                (error) => {
                    Swal.fire("Deleted!", "ผิดพลาด.", "error");
                }
            );
        }
    });
});
$(document).on("click", ".delete-region", function () {
    // alert($(this).data('id'));
    Swal.fire({
        title: "ยืนยัน?",
        text: "คุณต้องการลบออกหรือไม่!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ใช่ ต้องการลบ!",
    }).then((result) => {
        if (result.isConfirmed) {
            axios.delete("/manage_region/" + $(this).data("key")).then(
                (response) => {
                    Swal.fire("Deleted!", "ลบสำเร็จ.", "success");
                    window.location.href = "/manage_region";
                },
                (error) => {
                    Swal.fire("Deleted!", "ผิดพลาด.", "error");
                }
            );
        }
    });
});

function decodeBase64(base64) {
    const text = atob(base64);
    const length = text.length;
    const bytes = new Uint8Array(length);
    for (let i = 0; i < length; i++) {
        bytes[i] = text.charCodeAt(i);
    }
    const decoder = new TextDecoder(); // default is utf-8
    return decoder.decode(bytes);
}

window.gdprcookie = require("gdpr-cookie/gdpr-cookie.js");

$.gdprcookie.init();
$.gdprcookie.init({
    title: "",
    subtitle: "เลือกคุกกี้ที่จะอณุญาติ",
    message:
        "การใช้งานเว็บไซต์ decha-chicken แสดงว่าคุณได้ยอมรับ <a href='/terms'>ข้อตกลงและเงื่อนไขการใช้งาน</a> และ <a href='/privacy-policy'>นโยบายความเป็นส่วนตัว</a> ",
    delay: 2000, // delay in milliseconds
    expires: 30, // expiration <a href="https://www.jqueryscript.net/time-clock/">date</a>
    cookieName: "cookieControlPrefs",
    acceptReload: false,
    acceptBtnLabel: "รับทราบ",
    advancedBtnLabel: "ตั่งค่า",
    customShowMessage: undefined,
    customHideMessage: undefined,
    customShowChecks: undefined,
    cookieTypes: [
        {
            type: "บังคับใช้",
            value: "essential",
            description: "เป็นคุกกี้ที่บังคับใช้",
        },
        {
            type: "การวิเคราะห์",
            value: "analytics",
            description: "ไว้สำหรับเพิ่มประสิทธิภาพเว็บ",
        },
        {
            type: "facebook",
            value: "marketing",
            description: "facebook cookie ติดต่อสือสารผ่านเฟสบุค",
        },
    ],
});

//Get the button
let mybutton = document.getElementById("btn-back-to-top");

// When the user scrolls down 20px from the top of the document, show the button
// window.onscroll = function () {
//   scrollFunction();
// };

// function scrollFunction() {
//   if (
//     document.body.scrollTop > 20 ||
//     document.documentElement.scrollTop > 20
//   ) {
//     mybutton.style.display = "block";
//   } else {
//     mybutton.style.display = "none";
//   }
// }
// When the user clicks on the button, scroll to the top of the document
mybutton.addEventListener("click", backToTop);

function backToTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
}

// $(document).ready(function () {
//     $(window).on('load', function () {
//         $("#loading-body").fadeOut(0, function() {
//             $(this).removeClass("load-hide");
//             $('#fixed-top').addClass('fixed-top');
//             $('#car').addClass("animate__animated animate__backInRight");
//             $('#ck-show').addClass("animate__animated animate__backInLeft");
//         });
//       });

// });

$(document).ready(function () {

    $(".owl-carousel").owlCarousel({
        loop: true,
        margin:5,
        stagePadding: 0,
        nav: true,
        autoplay: true,
        autoplayTimeout: 3000,
        responsiveClass:true,
        autoplayHoverPause: true,
        center: true,
        navText: [
            '<i class="fa fa-angle-left "></i>',
            '<i class="fa fa-angle-right "></i>',
        ],
        responsive: {
            0: {
                items: 1,
            },
            1000: {
                items: 1,
            },
            1200: {
                items: 3,
            },
        },
    });
});

require("./bootstrap");
require("jquery-ui/ui/widgets/autocomplete.js");

$(document).on("click", ".alert-action", function () {
    $(".action-top-bar").slideUp();
});
$(window).scroll(function () {
    if ($(this).scrollTop() > 0) {
        $(".action-top-bar").slideUp();
    } else {
        $(".action-top-bar").slideDown();
    }
    if ($(this).scrollTop() > 200) {
        $("#car").removeClass("animate__animated animate__backInRight");
        $("#ck-show").removeClass("animate__animated animate__backInLeft");
        $("#ck-show").removeClass("animate__animated animate__backInRight");
        $("#car").addClass("animate__animated animate__backOutLeft");
    } else {
        $("#car").removeClass("animate__animated animate__backOutLeft");
        $("#ck-show").removeClass("animate__animated animate__backOutRight");
        $("#ck-show").addClass("animate__animated animate__backInRight");
        $("#car").addClass("animate__animated animate__backInRight");
    }
    // console.log($(this).scrollTop());
});

$(function () {
    $("#region")
        .autocomplete({
            source: function (request, response) {
                var url = "/api/region";
                // if (request.term != "") {
                //     url = url + "?query=" + request.term;
                // }
                $.ajax({
                    type: "POST",
                    url: url,
                    dataType: "json",
                    data: {
                        query: request.term,
                    },
                    success: function (data) {
                        if (data.length > 8) {
                            $(".ui-autocomplete").addClass(
                                "ui-autocomplete-sc"
                            );
                        } else {
                            $(".ui-autocomplete").removeClass(
                                "ui-autocomplete-sc"
                            );
                        }
                        var transformed = $.map(data, function (el) {
                            return {
                                label: el.region,
                                value: el.id,
                            };
                        });
                        response(transformed);
                    },
                    error: function () {
                        response([]);
                    },
                });
            },
            minLength: 0,
        })
        .focus(function () {
            $("#region-icon")
                .removeClass("fa-chevron-down")
                .addClass("fa-chevron-up");
            $(this).autocomplete("search", $(this).val());
        })
        .blur(function () {
            $("#region-icon")
                .removeClass("fa-chevron-up")
                .addClass("fa-chevron-down");
        });
});

// province

$("#province")
    .autocomplete({
        select: function (event, ui) {
            var label = ui.item.label;
            var value = ui.item.value;
            var body = {
                region: '',
                province:'',
                query: value
            }
            axios
                .post("api/province" ,querystring.stringify(body))
                .then(function (response) {
                    $("#region").val(response.data[0].region);
                });
        },
        source: function (request, response) {
            // var url = "";
            // url = "api/province?region=" + $("#region").val();
            // if (request.term != "") {
            //     url = url + "&query=" + request.term;
            // }
            // console.log(request);
            $.ajax({
                type: "POST",
                url: "api/province",
                data: {
                    query: request.term,
                    region: $("#region").val()
                },
                success: function (data) {
                    if (data.length > 8) {
                        $(".ui-autocomplete").addClass("ui-autocomplete-sc");
                    } else {
                        $(".ui-autocomplete").removeClass("ui-autocomplete-sc");
                    }
                    var transformed = $.map(data, function (el) {
                        return {
                            label: el["province"],
                        };
                    });
                    response(transformed);
                },
                error: function () {
                    response([]);
                },
            });
        },
        minLength: 0,
    })
    .focus(function () {
        $("#province-icon")
            .removeClass("fa-chevron-down")
            .addClass("fa-chevron-up");
        $(this).autocomplete("search", $(this).val());
    })
    .blur(function () {
        $("#province-icon")
            .removeClass("fa-chevron-up")
            .addClass("fa-chevron-down");
    });
$("#district")
    .autocomplete({
        select: function (event, ui) {
            var label = ui.item.label;
            var value = ui.item.value;
            var body = {
                region: '',
                province:'',
                query: value
            }

            axios
                .post("api/district", querystring.stringify(body))
                .then(function (response) {
                    $("#region").val(response.data[0].region);
                    $("#province").val(response.data[0].province);
                });
        },
        source: function (request, response) {

            $.ajax({
                type: "POST",
                url: "api/district",
                data: {
                    query: request.term,
                    province: $("#province").val()
                },
                success: function (data) {
                    if (data.length > 8) {
                        $(".ui-autocomplete").addClass("ui-autocomplete-sc");
                    } else {
                        $(".ui-autocomplete").removeClass("ui-autocomplete-sc");
                    }
                    var transformed = $.map(data, function (el) {
                        return {
                            label: el["district"],
                        };
                    });
                    response(transformed);
                },
                error: function () {
                    response([]);
                },
            });
        },
        minLength: 0,
    })
    .focus(function () {
        $("#district-icon")
            .removeClass("fa-chevron-down")
            .addClass("fa-chevron-up");
        $(this).autocomplete("search", $(this).val());
    })
    .blur(function () {
        $("#district-icon")
            .removeClass("fa-chevron-up")
            .addClass("fa-chevron-down");
    });





$("#search").on("click", function () {
    // var region = $("#region").val();
    // var province = $("#province").val();
    // var district = $("#district").val();
    // console.log(district.length);
    // return 0;
    if (region.length==0 || province.length==0 || district.length==0) {
        Swal.fire({
            icon: "error",
            title: "แจ้งเตือน",
            text: "กรุณากรอกข้อมูลให้ครบ",
        });
        return 0;
    }else{

        var body = {
            region: $("#region").val(),
            province: $("#province").val(),
            district: $("#district").val()
        }
    axios.post("api/getdata", querystring.stringify(body))
    .then(function (response) {
        // handle success
        // console.log(response);
        // return 0;

        if(check_round()){
            // console.log(response.data[0]);

            var data = response.data;
            var tell = convert_tell(response.data[0].details);
            var price = "";
            var out = "";

            if (data[0].price_chicken) {
                price +="<p><b >ราคาไก่/นก: </b> <label>" +
                    data[0].price_chicken +
                    "  </label> บาท</p>";
            }
            if (data[0].price_dog) {
                price +=
                    "<p><b >ราคาสุนัข: </b> <label>" +
                    data[0].price_dog +
                    " </label> บาท (ไม่เกิน 5 กก.)</p>";
            }

            out =' <div class="progress-bars">'
            +' <div class="step">'
            +'     <div class="step-container">'
            +'         <div class="bullet active"></div>'
            +'     </div>'
            +'     <p><b>ติดต่อส่ง:</b> <a href="tel:0932142944">093-2142944</a> (ต้น)</p>'
            +'     <div class="break"></div>'
            +'     <div class="price">'
            +price

            +'     </div>'
            +' </div>'
            +' <div class="step">'
            +'     <div class="step-container">'
            +'         <div class="bullet"></div>'
            +'         <div class="check fas fa-check"></div>'
            +'     </div>'
            +'     <p><b>เบอร์คนขับปลายทาง:</b></p>'
            +'     <div class="break"></div>'
            +'     <div class="price">'
            +'         <p>'+tell+'</p>'


            +'     </div>'
            +' </div>'
            +' <span>คำเตือน !!! เงื่อนไขการรับประกัน ใส่ไก่เกิน 1 ตัวไม่อยู่ในเงื่อนไขการรับประกัน'
            +'     สัตว์อื่นๆเช่น นก สุนัข แมว กระต่าย หนู อื่นๆไม่อยู่ในเงื่อนไขการรับประกัน</span>'
            +' </div>';

            // price +=
            //     '<br><h6 style="color:red">คำเตือน !!! เงื่อนไขการรับประกัน ใส่ไก่เกิน 1 ตัวไม่อยู่ในเงื่อนไขการรับประกัน</h6>';
            // price +=
            //     '<h6 style="color:red">สัตว์อื่นๆเช่น นก สุนัข แมว กระต่าย หนู อื่นๆไม่อยู่ในเงื่อนไขการรับประกัน</h6>';
            var tt= (parseInt(readCookie("check_round")) - 1);
            var tt_text ="";
            if(tt>0){
                tt_text = "(" +  tt + "/3)";
            }
            // Swal.fire(
            //     "รายละเอียด" +tt_text,
            //     '<div >' +
            //     out +
            //         "</div>",

            //     "success"
            // );
            Swal.fire({
                title: "รายละเอียด" +tt_text,
                icon: 'success',
                html:
                '<div >' +
                    out +
                        "</div>",
                showCloseButton: true,
                showCancelButton: false,
                focusConfirm: false,
                confirmButtonColor: "#4A4949",
                confirmButtonText:
                  'ปิดหน้าต่าง',

              });
        }else{
            count_round();
        }
    })
    .catch(function (error) {
        // console.log(error);
        Swal.fire(
            "ไม่พบข้อมูล",
            'ติดต่อ: <a href="tel:0899068830">089-9068830</a>',
            "error"
        );
        return 0;
    })
    .then(function () {
        // always executed
    });
    return 0;
    }




});
function convert_tell(details){
    var tes = details;

    const regex =
        /(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/g;
    var tel = tes.match(regex);
    // console.log(tel);
    var tell = "";
    if (tel) {
        tell = tes.replace(
            regex,
            "<a href='tel:" +
                tel[0].replace("-", "") +
                "'>" +
                tel[0] +
                "</a>"
        );
    } else {
        tell = tes;
    }
    return tell;
}
function count_round(){
    var d = new Date();
    var d2 = new Date(readCookie("etime"));
    var vd = d2 - d;
    var sec = Math.floor((vd % (1000 * 60)) / 1000);
    var mi = Math.floor((vd % (1000 * 60 * 60)) / (1000 * 60));

    // Swal.fire('แจ้งเตือน',mi+'ค้นหาได้สุงสุด 3 ครั่ง โปรดรอ 20 นาที แล้วกลับมาใหม่อีกครั่ง หรือติดต่อ: <a href="tel:0899068830">089-9068830</a>','warning');

    Swal.fire({
        title: "แจ้งเตือน",
        html: 'ค้นหาได้สุงสุด 3 ครั่ง <br>โปรดรอ <strong></strong> วินาที <br>แล้วค้นหาใหม่อีกครั้ง <br>หรือติดต่อ: <a href="tel:0899068830">089-9068830</a>',
        timer: vd,
        didOpen: () => {
            const content = Swal.getHtmlContainer();
            const $ = content.querySelector.bind(content);

            Swal.showLoading();

            timerInterval = setInterval(() => {
                var m = new Date(Swal.getTimerLeft());

                var dateString =
                    ("0" + m.getUTCMinutes()).slice(-2) +
                    ":" +
                    ("0" + m.getUTCSeconds()).slice(-2);
                Swal.getHtmlContainer().querySelector(
                    "strong"
                ).textContent = dateString;
            }, 100);
        },
        willClose: () => {
            clearInterval(timerInterval);
        },
    });
}
function check_round(){
    var tt = 1;

    if (readCookie("check_round")) {
        tt = parseInt(readCookie("check_round"));
    } else {
        createCookie("check_round", 1, 20);
        tt = 1;
    }

    if (tt > 3) {
        return false;
    }else{
        createCookie("check_round", tt + 1, 20);
        return true;
    }

}
function createCookie(name, value, time) {
    var expires;

    if (time) {
        var date = new Date();
        date.setTime(date.getTime() + time * 60 * 1000);
        expires = "; expires=" + date.toGMTString();
    } else {
        expires = "";
    }
    document.cookie =
        encodeURIComponent(name) +
        "=" +
        encodeURIComponent(value) +
        expires +
        "; path=/";
    document.cookie =
        encodeURIComponent("etime") +
        "=" +
        encodeURIComponent(date.toGMTString()) +
        expires +
        "; path=/";
}

function readCookie(name) {
    var nameEQ = encodeURIComponent(name) + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) {
            // console.log(ca);
            return decodeURIComponent(c.substring(nameEQ.length, c.length));
        }
    }
    return null;
}

function eraseCookie(name) {
    createCookie(name, "", -1);
}


